/* eslint-disable @typescript-eslint/no-explicit-any */
import { isPropertyAsset } from '@/helpers/typeGuards';
import { mapToApi } from '@/mappers';
import type { ApplicantAsset, IncomingProperty, LoanApplication, OutgoingProperty, PeriodKey } from '@/types';
import type { ApiSchema, FrequencyType } from '@/types/api';

const mapFrequency: Record<PeriodKey, FrequencyType> = {
  week: 'weekly',
  fortnight: 'fortnightly',
  month: 'monthly',
  quarter: 'quarterly',
  year: 'annually',
};

export const incomingProperty = (data: IncomingProperty): any => ({
  propertyType: data.propertyType,
  contractForSale: data.contractForSale,
  foundPropertyToPurchase: true,
  line1: data.address.addressLine,
  line2: data.address.addressLine2 ?? '',
  city: data.address.locality,
  state: mapToApi.australianState(data.address.state),
  postCode: data.address.postcode,
  country: 'au',
  propertyPurpose: data.propertyPurpose,
  depositAmount: data.depositAmount,
  purchasePrice: data.purchaseAmount,
  depositPaidType: data.depositType === 'cash_payment' ? 'cash_payment' : 'deposit_bond',
  settlementDate: data.settlementDate,
  stampDutyStatus: data.stampDutyStatus,
  transferFeeCovered: data.transferFeeCovered,
  addressManuallyCreated: data.address.addressManuallyCreated,
  rentalIncome: data.rentalIncome?.amount,
  rentalIncomeFrequency: data.rentalIncome?.frequency ? mapFrequency[data.rentalIncome?.frequency] : undefined,
});

export const outgoingProperty = (data: OutgoingProperty): any => ({
  propertyType: data.propertyType,
  line1: data.address.addressLine,
  line2: data.address.addressLine2 ?? '',
  city: data.address.locality,
  state: mapToApi.australianState(data.address.state),
  postCode: data.address.postcode,
  country: 'au',
  propertyPurpose: data.propertyPurpose,
  mortgageAgainst: data.outstandingLoanAmount > 0,
  outstandingMortgageAmount: data.outstandingLoanAmount,
  salePrice: data.saleStatus === 'sold' ? data.saleAmount : 0,
  depositAmountReceived: data.depositAmount,
  settlementDate: data.settlementDate,
  depositHeldInTrust: data.depositInTrust,
  listedForSale: data.saleStatus === 'listed_for_sale',
  estimatedValuation: data.saleStatus === 'sold' ? 0 : data.saleAmount,
  soldStatus: data.saleStatus,
  addressManuallyCreated: data.address.addressManuallyCreated,
});

export const propertyOwnership = (
  data: LoanApplication,
): (ApiSchema['CreatePropertyOwnershipDtoV1'] | ApiSchema['CreatePropertyOwnershipDtoV2'])[] => {
  const incoming = data.incomingProperties.flatMap((p, incomingPropertyIndex) => {
    const po = data.propertiesOwnership.find((i) => i.id === p.id);
    return po
      ? Object.entries(po.share).map(([applicantId, share]) => ({
          applicantIndex: mapToApi.applicantIndex(applicantId, data.applicants),
          incomingPropertyIndex,
          outgoingPropertyIndex: -1,
          share,
        }))
      : [];
  });

  const outgoing = data.outgoingProperties.flatMap((p, outgoingPropertyIndex) => {
    const po = data.propertiesOwnership.find((i) => i.id === p.id);
    return po
      ? Object.entries(po.share).map(([applicantId, share]) => ({
          applicantIndex: mapToApi.applicantIndex(applicantId, data.applicants),
          incomingPropertyIndex: -1,
          outgoingPropertyIndex,
          share,
        }))
      : [];
  });

  const applicantAssets = data.applicants.map((applicant) => ({
    applicantIndex: mapToApi.applicantIndex(applicant.id, data.applicants),
    assets: data.financials[applicant.id].assets,
  }));

  const assets: ApplicantAsset[] = [];
  for (const applicant of applicantAssets) {
    if (applicant.assets.length) {
      applicant.assets.forEach((a) => assets.push(a));
    }
  }

  const assetPo = assets.filter(isPropertyAsset).flatMap((p, assetPropertyIndex) => {
    if (isPropertyAsset(p)) {
      return p.propertyOwnership
        ? Object.entries(p.propertyOwnership.share).map(([applicantId, share]) => ({
            applicantIndex: mapToApi.applicantIndex(applicantId, data.applicants),
            incomingPropertyIndex: -1,
            outgoingPropertyIndex: -1,
            assetPropertyIndex,
            share,
          }))
        : [];
    } else {
      return [];
    }
  });

  return [...incoming, ...outgoing, ...assetPo];
};

export const applicantDeclarationPropertyOwnership = (
  data: LoanApplication,
  assets: ApplicantAsset[],
): (ApiSchema['CreatePropertyOwnershipDtoV1'] | ApiSchema['CreatePropertyOwnershipDtoV2'])[] => {
  const assetPo = assets.filter(isPropertyAsset).flatMap((p, assetPropertyIndex) => {
    if (isPropertyAsset(p)) {
      return p.propertyOwnership
        ? Object.entries(p.propertyOwnership.share).map(([applicantId, share]) => ({
            applicantIndex: mapToApi.applicantIndex(applicantId, data.applicants),
            incomingPropertyIndex: -1,
            outgoingPropertyIndex: -1,
            assetPropertyIndex,
            share,
          }))
        : [];
    } else {
      return [];
    }
  });
  return [...assetPo];
};
