import { GET, setParams } from '../_http';
import type { operations } from '@/types/schemas/calcs.main';
type controller = operations['StampDutyController_byState'];

export type TransferFeeParams = controller['parameters']['path'];
export type TransferFeeResponse = controller['responses']['200']['content']['application/json'];

export const transferFee = GET(
  '/formulas/transfer-fee/state/{state}/{purchasePrice}',
  setParams<TransferFeeParams>,
)<TransferFeeResponse>;
