<script setup lang="ts">
import { computed, watchEffect } from 'vue';
import { useForm } from 'vee-validate';
import { defaultIncomingProperty } from '@/helpers/builder';
import { brokerIncomingPropertySchema } from '@/validation';
import type { IncomingProperty, IncomingPropertyEntry } from '@/types';
import {
  propertyPurposeOptions,
  stampDutyStatusOptions,
  StampDutyStatus,
  brokerIncomingPropertyTypeOptions,
  PropertyType,
} from '@/helpers/const';
import { useCalcApi } from '@/helpers/useCalcApi';
import { useBrokerLoanAppProcess } from '@/helpers/useBrokerLoanAppProcess';
import type { DepositPaidType } from '@/api/enums';
import IncomingPropertyReceiveRentalIncome from '@/components/application/incoming-property-receive-rental-income.vue';
import IncomingPropertyExpectedRentalIncome from '@/components/application/incoming-property-expected-rental-income.vue';
import IncomingPropertyTransferFee from '@/components/application/incoming-property-transfer-fee.vue';
const { state, loanApp, saveAndGoNext, goBack, send } = useBrokerLoanAppProcess();
let propertyCounter = 0;
type IncomingPropertyWithSettlementDate = IncomingPropertyEntry & { settlementDate: string };

const depositTypeOptions: Record<DepositPaidType, string> = {
  ['cash_payment']: 'Cash payment',
  ['deposit_bond']: 'Deposit bond',
};

const {
  values,
  setFieldValue,
  meta,
  submitCount,
  handleSubmit: validateSubmit,
  resetForm,
} = useForm<IncomingPropertyWithSettlementDate>({
  validationSchema: brokerIncomingPropertySchema,
  initialValues: loanApp.value.incomingProperties.length
    ? { ...loanApp.value.incomingProperties[0] }
    : { id: `incoming-property-${propertyCounter++}` },
  validateOnMount: false,
});

if (loanApp.value.incomingProperties.length) {
  resetForm({ values: loanApp.value.incomingProperties[0] });
}

const singleSecurityRequired = computed<boolean>(() => {
  return (
    values.propertyType === PropertyType.RETIREMENT_LAND_OR_COMMUNITY_HOME ||
    values.propertyType === PropertyType.VACANT_LAND
  );
});

// note: "already_paid" is only valid if contract for sale exists
const validStampDutyPaymentOptions = computed(() =>
  Object.fromEntries(
    Object.entries(stampDutyStatusOptions).filter(
      ([id]) => values.contractForSale || id !== StampDutyStatus.ALREADY_PAID,
    ),
  ),
);
watchEffect(() => {
  if (!values.contractForSale && values.stampDutyStatus === StampDutyStatus.ALREADY_PAID) {
    setFieldValue('stampDutyStatus', undefined);
  }
});

const calc = useCalcApi();

const handleSubmit = validateSubmit(async (values) => {
  if (singleSecurityRequired.value) {
    send({
      type: 'UPDATE',
      loanApp: {
        ...loanApp.value,
        loanType: 'single_security',
      },
    });
    send({ type: 'GOTO', step: 'brokerLoanPurpose' });
    return;
  }

  const property: IncomingProperty = Object.assign(defaultIncomingProperty(), values);
  property.stampDuty = await calc.getStampDuty(property);
  property.transferFee = await calc.getTransferFee(property);

  loanApp.value.settlementDate = values.settlementDate;

  saveAndGoNext({ incomingProperties: [property] });
});
</script>

<template>
  <div>
    <div class="mb-8 pb-2">
      <h2 class="text-2xl font-medium">Tell us about the property your client is <BxHighlight>buying</BxHighlight>?</h2>
    </div>
    <form @submit="handleSubmit">
      <div>
        <BxField v-slot="{ field }" name="propertyType">
          <BxSelect
            v-bind="field"
            :id="values.id + '-incoming-property-type'"
            label="Property Type"
            label-type="floating"
            placeholder="Select property type..."
            :list-items="brokerIncomingPropertyTypeOptions"
            class="form-control"
          />
          <div v-if="singleSecurityRequired && values.propertyType" class="form-control mt-6">
            <p>
              This property type requires a single security bridging loan, please select the button below to complete
              the single security application.
            </p>
          </div>
        </BxField>
        <BxField v-if="!singleSecurityRequired && values.propertyType" v-slot="{ field }" name="address">
          <BxAddressInput
            :id="values.id + '-incoming-property-address'"
            v-bind="field"
            label="Property Address"
            label-type="floating"
            class="form-control"
          />
        </BxField>
        <BxField v-if="!singleSecurityRequired && values.propertyType" v-slot="{ field }" name="propertyPurpose">
          <BxRadio
            v-bind="field"
            :id="values.id + '-property-purpose'"
            :options="propertyPurposeOptions"
            label="What is the purpose of this property?"
            label-type="default"
            class="form-control"
            block
          />
        </BxField>
        <IncomingPropertyReceiveRentalIncome v-if="!singleSecurityRequired && values.propertyType" :id="values.id" />
        <IncomingPropertyExpectedRentalIncome
          v-if="!singleSecurityRequired && values.propertyType && values.receiveRentalIncome"
          :id="values.id"
        />
        <BxField v-if="!singleSecurityRequired && values.propertyType" v-slot="{ field }" name="contractForSale">
          <BxRadio
            v-bind="field"
            :id="values.id + '-contract-of-sale'"
            label="Do you have a contract for sale?"
            label-type="default"
            class="form-control"
            boolean
          />
        </BxField>
        <BxField v-if="values.contractForSale !== undefined" v-slot="{ field }" name="settlementDate">
          <BxDatePicker
            v-bind="field"
            :id="values.id + '-' + (values.contractForSale ? 'settlement-date' : 'estimated-purchase-date')"
            :label="values.contractForSale ? 'Settlement Date' : 'Estimated Purchase Date'"
            label-type="floating"
            placeholder="DD/MM/YYYY"
            class="form-control"
            :min-date="new Date()"
          />
        </BxField>
        <BxField v-if="values.contractForSale !== undefined" v-slot="{ field }" name="purchaseAmount">
          <BxNumberInput
            v-bind="field"
            :id="values.id + '-' + (values.contractForSale ? 'purchase-price' : 'estimated-purchase-price')"
            :label="values.contractForSale ? 'Purchase Price' : 'Estimated Purchase Price'"
            label-type="floating"
            leading-symbol="$"
            class="form-control"
          />
        </BxField>
        <BxField v-if="values.contractForSale !== undefined" v-slot="{ field }" name="depositAmount">
          <BxNumberInput
            v-bind="field"
            :id="values.id + '-' + (values.contractForSale ? 'deposit-amount' : 'estimated-deposit-amount')"
            :label="values.contractForSale ? 'Deposit Amount' : 'Estimated Deposit Amount'"
            label-type="floating"
            leading-symbol="$"
            class="form-control"
          />
        </BxField>
        <BxField v-if="values.contractForSale !== undefined" v-slot="{ field }" name="depositType">
          <BxRadio
            v-bind="field"
            :id="values.id + '-deposit-type'"
            :options="depositTypeOptions"
            :label="values.contractForSale ? 'How was your deposit paid?' : 'How will you pay your deposit?'"
            label-type="default"
            class="form-control"
          />
        </BxField>
        <BxField v-if="values.contractForSale !== undefined" v-slot="{ field }" name="stampDutyStatus">
          <BxRadio
            v-bind="field"
            :id="values.id + '-stamp-duty-paid'"
            :options="validStampDutyPaymentOptions"
            label="How will stamp duty be paid?"
            label-type="default"
            class="form-control"
            block
          />
        </BxField>
        <IncomingPropertyTransferFee v-if="values.contractForSale !== undefined" :id="values.id" />
      </div>
      <BxErrorPanel v-show="!meta.valid && submitCount > 0" class="mt-6">
        Please review the error messages above.
      </BxErrorPanel>
      <div class="mt-6">
        <BxButton id="incoming-properties-continue" variant="primary" type="submit">{{
          singleSecurityRequired ? 'Single security' : 'Save and continue'
        }}</BxButton>
        <BxButton v-if="state.nextEvents.includes('BACK')" variant="link" class="ml-4" @click="goBack()">Back</BxButton>
      </div>
    </form>
  </div>
</template>

<style lang="scss" scoped>
.link {
  @apply cursor-pointer text-bridgit-navy underline hover:text-bridgit-vibrantGreen;
}
</style>
